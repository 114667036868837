<template>
  <div>
    <v-list class="promotion-card-select text-center mt-3">
      <v-list-item class="mb-5 px-0 promotion-card-item" v-for="bonus in bonusHistoryList" :key="bonus.id">
        <v-card>
          <v-img @click="openPromotionTnc(bonus)" class="promotion-image-box" :src="`/static/image/promotion_icons/${currentLanguage}/${bonus.id}.jpg?v=1`"></v-img>
        </v-card>
        <v-card-text class="promotion-name-box text-left">
          <v-row>
            <v-col class="py-0 font-weight-bold text-uppercase" cols="12">{{ getBonusTitle(bonus.locale) }}</v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col class="shrink py-0" cols="auto">
              {{ $t(`label.amount`) + ' : ' }}
              <label>{{ bonus.bonusGivenAmount | currency }}</label>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-row no-gutters>
                <v-col class="shrink mr-2 py-0" cols="auto">{{ $t(`label.status`) + ' : ' }}</v-col>
                <v-col class="grow py-0" cols="auto" v-if="bonus.status.toLowerCase() == 'serving' || bonus.status.toLowerCase() == 'release'">
                  <v-progress-linear rounded :value="truncatetwodec(bonus.bonusTotalProgress * 100)" height="20" reactive color="primary_3">
                    <template v-slot="{ value }">
                      <strong>{{ value }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-col class="py-0 text-center title_color--text" cols="auto" v-else-if="bonus.status.toLowerCase() == 'pending'">{{ $t(`bonusStatus.${bonus.status.toLowerCase()}`) }}</v-col>
                <v-col class="grow py-0" cols="auto" v-else>
                  <v-btn icon color="white" class="primary promotion-progress-claim-button" width="100%" @click="claimBonus(bonus)">{{ $t(`bonusStatus.${bonus.status.toLowerCase()}`) }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-list-item>
    </v-list>
    <p v-if="bonusHistoryList.length <= 0" class="text-center text-capitalize">{{ $t(`label.noInProgressBonus`) }}</p>

    <app-dialog :dialogShow="promotionDialog.show" :max-width="1100" :title="promotionDialog.title" :closeAction="promotionDialog.dialogXButton">
      <div :class="['container-promo px-0', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' : 'fill-height']">
        <v-row no-gutters>
          <v-col cols="12">
            <v-col class="py-1">
              {{ $t(`label.amount`) + ' : ' }}
              <label class="font-weight-bold navigator_active--text">{{ selectedBonus.bonusGivenAmount | currency }}</label>
            </v-col>
            <v-col class="py-1">
              {{ $t(`label.expiredDate`) + ' : ' }}
              <label>{{ selectedBonus.bonusExpireDate | longDate }}</label>
            </v-col>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-col class="py-1">
              {{ $t(`label.dateApplied`) + ' : ' }}
              <label>{{ selectedBonus.bonusApplyDate | longDate }}</label>
            </v-col>
            <v-col class="py-1">
              <v-row no-gutters>
                <v-col class="shrink mr-2 py-0" cols="auto">{{ $t(`label.status`) + ' : ' }}</v-col>
                <v-col class="shrink mr-2 py-0" cols="auto" v-if="selectedBonus != '' && selectedBonus.status.toLowerCase() == 'pending'">
                  {{ $t(`bonusStatus.${selectedBonus.status.toLowerCase()}`) }}
                </v-col>

                <v-col class="grow py-0" cols="auto" v-if="selectedBonus != '' && selectedBonus.status.toLowerCase() != 'pending'">
                  <v-progress-linear rounded :value="truncatetwodec(selectedBonus.bonusTotalProgress * 100)" height="25" reactive color="primary_3">
                    <template v-slot="{ value }">
                      <strong>{{ value }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-col>

                <v-col class="shrink py-0" cols="auto" v-if="selectedBonus != '' && selectedBonus.status.toLowerCase() != 'pending'">
                  <v-btn icon tile color="white" class="primary promotion-progress-more-button" width="auto" @click="showBonusProgressDetail = !showBonusProgressDetail">
                    <v-icon>more_horiz</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="py-2" v-if="showBonusProgressDetail">
          <v-col cols="12" class="py-0">
            <v-col cols="12" class="py-0" v-for="bonusProgress in selectedBonus.bonusProgress" :key="bonusProgress.name">
              <label>
                {{
                  $t(
                    `bonusProgressTitle.${bonusProgress.name
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .replace(/\./g, '')}`
                  )
                }}
                :
              </label>
              <v-progress-linear rounded :value="truncatetwodec((bonusProgress.current_progress / bonusProgress.total_progress) * 100)" height="25" reactive color="primary_3">
                <template>
                  <strong>{{ `${truncatetwodec(bonusProgress.current_progress / bonusProgress.total_progress)}` }}</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-2">
          <v-divider></v-divider>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <iframe class="promotion-iframe" :src="promotionTncUrl" frameborder="0"></iframe>
          </v-col>
        </v-row>
      </div>
    </app-dialog>
  </div>
</template>
<script>
import { DevicePlatform } from '@/constants/enums'
import { uiHelper, locale, errorCodeHelper } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
import { BONUS_HISTORY, CLAIM_BONUS, RESET_CLAIM_BONUS_STORE } from '@/store/bonus.module'
import axios from 'axios'
import { SHARED } from '@/constants/constants'

export default {
  name: 'promotion',
  data: () => ({
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    currentLanguage: uiHelper.getLanguage(),
    selectedBonus: '',
    promotionFound: false,
    showBonusProgressDetail: true,
    promotionTncUrl: '',
    promotionDialog: {
      show: false,
      title: locale.getMessage(`label.promotion`),
      dialogXButton: function() {}
    },
    selectedCategory: 'all'
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    bonusHistoryList() {
      return this.$store.state.bonus.historyList
    },
    claimBonusResponseComplete() {
      return this.$store.state.bonus.claimBonusResponse
    }
  },
  watch: {
    claimBonusResponseComplete() {
      let response = this.$store.state.bonus.claimBonusResponse
      if (response.complete) {
        this.claimBonusResponseCompleteDialog(response)
        this.$store.dispatch(`${RESET_CLAIM_BONUS_STORE}`)
      }
    }
  },
  created() {
    this.getPromotionHistory()
  },
  methods: {
    truncatetwodec(val) {
      let num = val
      let with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return with2Decimals
    },
    claimBonusResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.claimBonusSuccessful`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }
      this.getPromotionHistory()
      this.$parent.$parent.openPageDialog(dialog)
    },
    claimBonus(bonus) {
      let id = bonus.applicantId
      this.$store.dispatch(`${CLAIM_BONUS}`, { id })
    },
    getBonusTitle(bonusLocale) {
      if (bonusLocale.find(x => x.language == this.currentLanguage) != null) {
        return bonusLocale.find(x => x.language == this.currentLanguage).title
      } else if (bonusLocale.find(x => x.language == 'en') != null) {
        bonusLocale.find(x => x.language == 'en').title
      } else {
        return bonusLocale[0].title
      }
    },
    getPromotionHistory() {
      let bonusHistoryObj = {
        overallStatus: 'pending',
        fromDate: '',
        toDate: '',
        page: '',
        itemsPerPage: ''
      }
      this.$store.dispatch(`${BONUS_HISTORY}`, { bonusHistoryObj })
    },
    getPromotionTncFile(id) {
      let timestamp = new Date().getTime()
      let language = uiHelper.getLanguage()
      if (language == 'enin') {
        language = 'en'
      }
      let fileUrl = `${`${this.mediaUrl}/html/promotion/${language}/${id}.html?v=${timestamp}`}`
      let promotionNotFoundUrl = `${process.env.BASE_URL}${`static/html/promotion/${this.currentLanguage}/non-found.html`}`
      this.promotionDialog.dialogXButton = this.closePromotionTncDialog
      axios({
        method: 'GET',
        url: fileUrl,
        responseType: 'arraybuffer'
      })
        .then(response => {
          if (response.status == 200) {
            this.promotionTncUrl = fileUrl
            this.promotionDialog.show = true
          }
        })
        .catch(() => {
          this.promotionTncUrl = promotionNotFoundUrl
          this.promotionDialog.show = true
          console.log(`Promotion ID : ${id} `)
        })
    },
    applyBonus() {
      if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.DEPOSIT,
          params: {
            bonusId: this.selectedBonusId,
            lang: this.$route.params.lang
          }
        })
        window.open(routeData.href)
      } else {
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT,
          params: {
            bonusId: this.selectedBonusId,
            lang: this.$route.params.lang
          }
        })
      }
    },
    promptLogin() {
      this.$parent.goToLoginPage()
    },
    openPromotionTnc(bonus) {
      this.selectedBonus = bonus
      if (this.selectedBonus.status.toLowerCase() == 'pending') this.showBonusProgressDetail = false
      else this.showBonusProgressDetail = true
      this.getPromotionTncFile(bonus.id)
    },
    closePromotionTncDialog() {
      this.promotionTncUrl = ''
      this.promotionDialog.show = false
      this.selectedBonus = ''
    }
  }
}
</script>
<style lang="scss">
.container-promo {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}

.maxFrameHeight {
  max-height: 500px;
}

.promotion-progress-more-button {
  height: 26px !important;
  padding: 0 10px;
}

.promotion-progress-claim-button {
  height: 26px !important;
  padding: 0 10px;
  width: 100%;
}

.title_color--text {
  font-size: 14px;
}
</style>
